import "../assets/scss/styles.scss";
import "@carybit/lead-generation-form/dist/index.css";
import configFile from "../../config.json";
import { LeadGenerationForm, IConfig } from "@carybit/lead-generation-form";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const MainComponent = () => {
    const [config, setConfig] = useState<IConfig>(configFile);
    const location = useLocation();
    const [embed, setEmbed] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);

        const embedValue = queryParams.get("embed");

        const isEmbed = embedValue === "true";

        setEmbed(isEmbed);
    }, [location.search]);

    return (
        <LeadGenerationForm
            config={{ ...config, isEmbed: embed }}
            onAnswerSelected={{
                "6496a394d64fbb65ee41041c": () => {
                    setConfig({
                        ...config,
                        contactForm: {
                            title: "Your Final Step",
                            fields: [
                                { name: "Name", type: "text", id: "name", isRequired: true, placeholder: "John Doe" },
                                { name: "Email", type: "text", id: "email", isRequired: true, placeholder: "john.doe@mail.com" },
                                { name: "Phone", type: "text", id: "phone", isRequired: true, placeholder: "+1 234-567-8910", skipValidation: true }
                            ],
                        },
                        questionOverwrites: { "6496a396d64fbb65ee410437": "How do you generate the most revenue for your clients?" },
                    });
                },
                "6496a394d64fbb65ee41041f": () => {
                    setConfig({
                        ...config,
                        contactForm: {
                            title: "Your Final Step",
                            fields: [
                                { name: "Name", type: "text", id: "name", isRequired: true, placeholder: "John Doe" },
                                { name: "Email", type: "text", id: "email", isRequired: true, placeholder: "john.doe@mail.com" },
                                { name: "Phone", type: "text", id: "phone", isRequired: true, placeholder: "+1 234-567-8910", skipValidation: true },
                                { name: "Company", type: "text", id: "company", isRequired: true, placeholder: "Your Company Inc." },
                                { name: "Website", type: "text", id: "website", isRequired: false, placeholder: "www.website.com" },
                            ],
                        },
                        questionOverwrites: { "6496a396d64fbb65ee410437": "How do you generate the most revenue for your business?" },
                    });
                },
            }}
        />
    );
};

export default MainComponent;
